/*
  Credits to https://esstudio.site/2019/02/16/downloading-saving-and-opening-files-with-cordova.html
*/

import { isAndroid, isIOS } from 'packages/platform-detector';
import { CordovaGlobal } from 'apps/acp/packages/environment';

declare global {
  interface Window {
    resolveLocalFileSystemURL: (
      folderPath: string,
      fn: (dir: any) => void,
      onFailure: () => void
    ) => void;
  }
}

export interface FileDownloaderPlugin {
  /**
   * Starts a file download.
   */
  downloadFile(filename: string, blob: Blob): void;
}

/**
 * to convert blob to base64
 */
function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onabort = reject;
    reader.onerror = reject;
    reader.onloadend = () => {
      const base64url = reader.result as string;
      if (base64url.startsWith(`data:${blob.type};base64,`)) {
        const base64Data = base64url.substring(
          `data:${blob.type};base64,`.length
        );
        resolve(base64Data);
      } else {
        reject('Failed to base64 encode PDF blob');
      }
    };
  });
}

/**
 * to support mobile browser as window.open will fail in mobile browser
 * and if there are any blockers in browser
 */
async function downloadBrowserFallback(filename: string, data: Blob) {
  let base64;
  try {
    base64 = await blobToBase64(data);
  } catch {
    return Promise.reject();
  }
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${data.type};base64,${encodeURIComponent(base64)}`
  );
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  /**
   * this acts like a fallback when unable to open file due to top frame error.
   *
   * Note: Fallback - will only download the file instead of opening it
   */
  element.setAttribute('download', filename);
  element.click();
  document.body.removeChild(element);
}

/**
 * to support both mobile and desktop web
 */
async function downloadBrowser(filename: string, data: Blob) {
  /**
   * for the desktop browser
   */
  const fileURL = URL.createObjectURL(data);
  const newWindow = window.open(fileURL);
  /**
   * for the mobile browser and if there are any blockers in desktop browser
   */
  if (!(newWindow !== null && newWindow.closed !== true)) {
    URL.revokeObjectURL(fileURL);
    await downloadBrowserFallback(filename, data);
  }
}

/**
 * to support mobile and desktop browsers
 */
export const createFileDownloaderPluginBrowser = (): FileDownloaderPlugin => {
  return {
    downloadFile(filename: string, blob: Blob) {
      downloadBrowser(filename, blob);
    }
  };
};

/**
 * to support mobile application
 */
export const createFileDownloaderPluginMobile = (
  cordova: CordovaGlobal
): FileDownloaderPlugin => {
  return {
    downloadFile(filename: string, blob: Blob) {
      const onFailure = () => {
        alert('Unable to download');
      };
      if (cordova && cordova.platformId !== 'browser') {
        let storageLocation = '';
        if (isAndroid()) {
          storageLocation = (cordova as CordovaGlobal).file
            .externalDataDirectory;
        } else if (isIOS()) {
          storageLocation = (cordova as CordovaGlobal).file.documentsDirectory;
        }
        window.resolveLocalFileSystemURL(
          storageLocation,
          function (dir: any) {
            dir.getFile(
              filename,
              {
                create: true
              },
              function (file: any) {
                file.createWriter(function (fileWriter: any) {
                  fileWriter.write(blob);

                  fileWriter.onwriteend = function () {
                    const url = file.toURL();
                    cordova.plugins.fileOpener2.open(url, blob.type, {
                      error: onFailure
                    });
                  };
                  fileWriter.onerror = onFailure;
                }, onFailure);
              },
              onFailure
            );
          },
          onFailure
        );
      }
    }
  };
};
